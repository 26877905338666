@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

// Generate color palette visit http://mcg.mbitson.com and enter base color which will be 500
// use for contrast white, $white-87-opacity, black and $black-87-opacity

$immocheck-green: (
  50: #e6eaec,
  100: #bfcbcf,
  200: #95a8af,
  300: #6b858e,
  400: #4b6b76,
  500: #2b515e,
  600: #264a56,
  700: #20404c,
  800: #1a3742,
  900: #102731,
  A100: #6fcfff,
  A200: #3cbeff,
  A400: #09adff,
  A700: #009fef,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$immocheck-orange: (
  50: #fbebe0,
  100: #f5cdb3,
  200: #efac80,
  300: #e88b4d,
  400: #e37226,
  500: #de5900,
  600: #da5100,
  700: #d54800,
  800: #d13e00,
  900: #c82e00,
  A100: #fff3f1,
  A200: #ffc8be,
  A400: #ff9d8b,
  A700: #ff8871,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$immocheck-red: (
  50: #fee8e8,
  100: #fcc5c5,
  200: #fa9e9e,
  300: #f87777,
  400: #f75a5a,
  500: #f53d3d,
  600: #f43737,
  700: #f22f2f,
  800: #f02727,
  900: #ee1a1a,
  A100: #ffffff,
  A200: #ffeded,
  A400: #ffbaba,
  A700: #ffa1a1,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

// mandatory stuff for theming
$immocheck-palette-primary: mat.m2-define-palette($immocheck-green);
$immocheck-palette-accent: mat.m2-define-palette($immocheck-orange);
$immocheck-palette-warn: mat.m2-define-palette($immocheck-red);

// include the custom theme components into a theme object
$immocheck-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $immocheck-palette-primary,
      accent: $immocheck-palette-accent,
      warn: $immocheck-palette-warn,
    ),
  )
);

// include the custom theme object into the angular material theme
@include mat.all-component-themes($immocheck-theme);

mat-form-field.item {
  font-size: 16px;
  color: #000;
  width: 100%;
  .mat-form-field-infix {
    padding-bottom: 12px;
  }
  .mat-form-field-underline {
    background-color: var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  }
}
