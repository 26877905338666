@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@fontsource/roboto';
@import '@fontsource/material-icons-outlined';
@import '@fontsource/material-symbols-outlined';

html {
  scroll-behavior: smooth;
}

// necessary because immo components use non outlined icons
.material-icons {
  @apply material-icons-outlined;
}

.material-symbols {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

mat-icon.immo-icon svg {
  width: 100%;
  height: auto;
}

@layer components {
  .chip-sky {
    background-color: theme('colors.sky.200');
    color: theme('colors.sky.800');
  }

  .chip-default {
    background-color: theme('colors.gray.200');
    color: theme('colors.gray.800');
  }
}

ion-input.title {
  font-size: 20px;
  font-weight: 500;
}

.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

table {
  width: 100%;
  tr {
    @apply cursor-pointer;
  }
  tr:hover .mat-cell {
    @apply bg-primary bg-opacity-5 text-primary;
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: var(--ion-color-primary);
}

.cdk-overlay-container {
  .searchbar-input.sc-ion-searchbar-md {
    box-shadow: none;
  }
}

.single-select-alert {
  --min-width: 500px;
  --width: auto;
  --min-height: auto;
  --height: auto;
  --max-height: 90%;
}

markdown h2 {
  text-align: left;
}

.cdk-overlay-container {
  z-index: 50003;
}

ion-grid {
  --ion-grid-width: 1248px;
}

.mat-column-id {
  display: none;
}

ion-spinner {
  height: 14px;
  --color: var(--ion-color-primary);
}

ion-modal.big {
  .modal-wrapper[role='dialog'] {
    min-width: 80vw;
    min-height: 80vw;
  }
}

ion-modal.big {
  .modal-wrapper[role='dialog'] {
    min-width: 100vw;
    min-height: 100vh;
  }
}

@media only screen and (min-width: 800px) {
  ion-modal.big {
    .modal-wrapper[role='dialog'] {
      min-width: 60vw;
      min-height: 60vh;
    }
  }
}

@media only screen and (min-width: 600px) {
  ion-modal.big {
    .modal-wrapper[role='dialog'] {
      min-width: 80vw;
      min-height: 80vh;
    }
  }
}

// .hint {
//   svg.arrow {
//     fill: var(--ion-color-primary);
//   }
// }

// @keyframes shadow-pulse {
//   0% {
//     border-radius: 28px;
//     box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
//   }
//   100% {
//     border-radius: 28px;
//     box-shadow: 0 0 0 28px rgba(0, 0, 0, 0);
//   }
// }

// .price-row {
//   @apply flex flex-row items-end justify-between;
//   h3 {
//     color: var(--ion-color-secondary);
//   }
// }

// .price-divider {
//   margin-top: 12px;
//   margin-bottom: 12px;
//   background: var(--ion-color-white);
//   height: 4px;
//   border-radius: 2px;
// }

// .prices {
//   width: 100%;
//   background: var(--ion-color-light);
//   display: flex;
//   flex-direction: column;
// }

// .price-overview {
//   width: 100%;
// }

// since tailwind added, refactor styles above

// .logo {
//   width: 40px;
//   height: 40px;
// }
h2 {
  @apply mb-2 mt-1 text-2xl font-medium;
}

h3 {
  @apply text-xl;
}

h1 {
  @apply mb-2 mt-1 text-3xl font-medium;
}

b {
  @apply font-medium text-secondary-shade;
}

b.light {
  @apply text-secondary-light;
}

video {
  @apply mx-auto;
}

em {
  font-style: normal;
  @apply text-tertiary;
}

app-time-report .mat-form-field-infix {
  width: auto;
}

// .curved {
//   @apply relative;
// }

// .curved::after {
//   content: '';
//   @apply absolute bottom-0 w-full;
// }

// .curved-primary::after {
//   @apply bg-primary;
// }

// .curved-white::after {
//   @apply bg-white;
// }

// .curved-up::after {
//   @apply h-6;
//   border-top-left-radius: 50% 100%;
//   border-top-right-radius: 50% 100%;
// }
// .curved-down::after {
//   height: 50vh;
//   border-bottom-left-radius: 50% 1.5rem;
//   border-bottom-right-radius: 50% 1.5rem;
// }

// swiper {
//   --swiper-theme-color: #fff;
// }

// .swiper-pagination {
//   margin-bottom: -1rem;
// }

.page {
  @apply relative block w-full space-y-4;
}

.item {
  @apply flex w-full items-center space-x-4 rounded-md p-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 disabled:cursor-not-allowed disabled:bg-gray-200;
}

.card.overlay immo-search.max-w-md {
  max-width: 100%;
}

.mat-tooltip {
  @apply text-sm;
}

.btn {
  @apply text-center;
}

.hide-x {
  /* clears the 'X' in searchbar from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.btn-red {
  background-color: theme('colors.red.500');
  color: theme('colors.red.100');
  &:hover:not(:disabled) {
    background-color: theme('colors.red.600');
  }
}
